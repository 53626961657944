import type { ComponentProps, RenderableProps } from 'preact';

type Props = RenderableProps<
	Omit<ComponentProps<'a'>, 'href' | 'rel'> & {
		href: string;
		rel?: string;
	}
> &
	ComponentProps<'button'>;
export function MaskedLink({ href, target, rel, children, ...props }: Props) {
	const shouldOpenInNewTab = target === '_blank';
	return (
		<button
			type="button"
			aria-label="Link"
			{...props}
			onClick={() => openUrl(href, { shouldOpenInNewTab, rel })}
		>
			{children}
		</button>
	);
}

function openUrl(
	url: string,
	options?: {
		shouldOpenInNewTab?: boolean;
		rel?: string;
	}
) {
	const { shouldOpenInNewTab = false, rel = '' } = options || {};
	const windowFeatures = [...new Set(['noopener', ...rel.split(' ')])];
	if (shouldOpenInNewTab) {
		const newWindow = window.open(url, '_blank', windowFeatures.join(','));
		if (newWindow) {
			newWindow.opener = null;
		}
		return;
	}
	window.location.href = url;
}
