import { useStore } from '@nanostores/preact';
import type { ComponentProps, RenderableProps } from 'preact';
import { useState } from 'preact/hooks';

import { Button } from '@components/Button';
import '@components/Header.scss';
import DownCaret from '@components/icons/DownCaret';
import Logo from '@components/icons/Logo';
import { LoginPopup } from '@components/LoginPopup';
import { MaskedLink } from '@components/MaskedLink';
import { MenuPromoBannerCtInspections } from '@components/MenuPromoBannerCtInspections';
import { navigationItems } from '@data/navData';

import { useTranslation } from '@i18n/use-translation';

import Hamburger from '@icons/Hamburger';
import Notification from '@icons/Notification';
import RightCaret from '@icons/RightCarret';
import { newListingPageLinks } from '@modules/vehicle-ad-wizard/data/wizardLinks';
import { openLoginPopup } from '@store/login-popup';
import {
	country as countryStore,
	fetchUser,
	locale,
	user as userStore,
	type User,
} from '@store/user';
import { isCtInspectionsPromotedInCountry } from '@utils/ct-services';

fetchUser(locale.get());

export const Header = () => {
	const { t, tIgnoredByParse } = useTranslation();
	const profile = useStore(userStore);
	const [isShown, setIsShown] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [isMobileUserShown, setIsMobileUserShown] = useState(false);
	const country = useStore(countryStore);
	const rootUrl = `/${country}`;
	const navigation = navigationItems;
	const hasNotification = profile.menuItems.some(
		(item) => item.hasNotification
	);

	const shouldDisplayCreateListingButton =
		profile.role && ['Dealer', 'Private seller'].includes(profile.role);

	return (
		<header className="sticky left-0 top-0 z-[21] mx-auto h-auto w-full bg-white md:relative ">
			<nav
				className="relative z-30 mx-auto"
				role="navigation"
				aria-label="Main menu"
				id="main-menu"
			>
				<div className="flex items-center justify-between py-2 lg:grid lg:grid-cols-[1fr,1fr,1fr] lg:gap-5">
					<div className="flex w-full max-w-[210px] grow md:mx-auto md:max-w-[250px] lg:mx-0 lg:max-w-[290px] lg:justify-self-start">
						<a
							href={rootUrl}
							className="block"
							aria-label="Go to homepage"
						>
							<Logo />
						</a>
					</div>
					<button
						type="button"
						className={`transition-all duration-300 lg:hidden ${
							openMenu ? 'rotate-90' : 'rotate-0'
						}`}
						onMouseDown={() => setOpenMenu(!openMenu)}
						aria-expanded={openMenu}
						aria-haspopup="true"
						aria-controls="main-menu"
						aria-label="Menu"
					>
						<Hamburger />
					</button>
					<ul
						className="main_nav relative z-10 hidden flex-grow items-center justify-center lg:flex lg:h-full lg:w-full"
						role="navigation"
					>
						{navigation.navList.map((navElement) =>
							navElement.hasSubmenu ? (
								<NavListItem navigationElement={navElement} />
							) : (
								navElement.href[country] && (
									<li
										key={navElement.id}
										className="px-5 py-3 "
										role="group"
									>
										<a
											href={navElement.href[country]}
											className="text-lg font-medium hover:text-brand-blue-default "
										>
											{tIgnoredByParse(
												navElement.i18nKey
											)}
										</a>
									</li>
								)
							)
						)}
					</ul>
					<div className="hidden items-center lg:flex lg:h-full lg:w-auto lg:justify-self-end">
						{profile.isLoggedIn ? (
							<div
								className={`relative z-0 px-5 py-1 transition-all duration-300 lg:pr-0 ${
									isShown ? ' bg-white' : ''
								}  `}
								onMouseEnter={() => setIsShown(true)}
								onMouseLeave={() => setIsShown(false)}
							>
								<div className="inline-flex items-center gap-x-4">
									<button
										type="button"
										className="flex cursor-pointer appearance-none items-center justify-center gap-x-2.5 border border-brand-blue-default bg-brand-white-default px-7 py-2.5 font-medium  leading-none  text-brand-blue-default transition-all duration-300 hover:bg-brand-white-hover focus:bg-brand-white-focus disabled:pointer-events-none disabled:border-brand-gray-dark"
									>
										{profile.isBusy ? (
											<span>{t('common.loading')}</span>
										) : null}
										{profile.hasError ? (
											<span>
												{t('common.signIn', 'Sign in')}
											</span>
										) : null}
										{!profile.isBusy &&
										!profile.hasError ? (
											<>
												{hasNotification ? (
													<div>
														<Notification />
													</div>
												) : null}
												{profile.displayName}
											</>
										) : null}
									</button>

									{isShown ? (
										<div className="user_navigation absolute left-0 top-full w-full bg-white p-5 shadow-subnavigation lg:left-[unset] lg:right-0 lg:min-w-[270px]">
											<UserNavigationElement
												items={profile.menuItems}
												userRole={profile.role}
											/>
										</div>
									) : null}
								</div>
							</div>
						) : (
							<div className="inline-flex">
								<Button
									type="button"
									kind="tertiary"
									size="medium"
									label={
										profile.isBusy
											? t('common.loading', 'Loading…')
											: t('common.signIn', 'Sign in')
									}
									onClick={() => openLoginPopup()}
								/>
							</div>
						)}
					</div>
				</div>
				{openMenu ? (
					<div className="absolute left-auto right-0 mx-auto max-h-[75vh] w-full max-w-[290px] overflow-hidden overflow-y-visible bg-white pt-7 shadow-subnavigation lg:hidden">
						<ul
							role="menu"
							className="flex flex-col items-start justify-center  px-4"
						>
							<li className="mb-6 w-full">
								{profile.id ? (
									<>
										<Button
											type="button"
											label={`${profile.displayName}`}
											kind="tertiary"
											iconName="login"
											fullWidth
											onClick={() =>
												setIsMobileUserShown(
													!isMobileUserShown
												)
											}
										/>
										{isMobileUserShown ? (
											<div className="submenu flex flex-grow flex-col bg-white pt-3 text-brand-blue-default">
												{shouldDisplayCreateListingButton ? (
													<CreateNewListingButton />
												) : null}

												{profile.menuItems.map(
													(singleItem) => (
														<a
															href={
																singleItem.url
															}
															className="flex justify-between py-1 text-brand-blue-default transition-all duration-300 hover:bg-brand-gray-footer"
														>
															{singleItem.title}{' '}
															<div>
																<span className="text-brand-orange-default">
																	{singleItem.count !==
																	null ? (
																		<span>
																			(
																			{
																				singleItem.count
																			}
																			)
																		</span>
																	) : (
																		''
																	)}
																</span>
																{singleItem.hasNotification ? (
																	<span className="ml-2 text-brand-green-default">
																		⏺
																	</span>
																) : null}
															</div>
														</a>
													)
												)}
											</div>
										) : null}
									</>
								) : (
									<Button
										type="button"
										label={t(
											'menu.loginOrCreateAccount',
											'Login / Create account'
										)}
										fullWidth
										kind="tertiary"
										iconName="login"
										target="_blank"
										onClick={() => openLoginPopup()}
									/>
								)}
							</li>
							{/* links list */}
							{navigation.navList.map((navElement) =>
								// eslint-disable-next-line no-nested-ternary
								navElement.hasSubmenu ? (
									<MobileNavListItem
										navigationElement={navElement}
									/>
								) : navElement.href &&
								  navElement.href[country] ? (
									<li
										key={navElement.id}
										className="mx-auto w-full border-t border-brand-gray-border px-4 py-6"
										role="group"
									>
										<a
											href={navElement.href[country]}
											className="text-lg font-medium hover:text-brand-blue-default"
										>
											{tIgnoredByParse(
												navElement.i18nKey
											)}
										</a>
									</li>
								) : null
							)}
						</ul>
						{isCtInspectionsPromotedInCountry(country) ? (
							<div className="w-full">
								<MaskedLink
									href="https://www.ct-inspections.com"
									target="_blank"
									rel="noopener"
									className="w-full bg-brand-blue-inspections px-6 py-4 text-left text-white transition-all duration-300"
								>
									<MenuPromoBannerCtInspections
										hasCaret={false}
										topLabel={t(
											'menu.ctInspectionsPromotion.title'
										)}
									/>
								</MaskedLink>
							</div>
						) : null}
					</div>
				) : null}
			</nav>

			<LoginPopup />
		</header>
	);
};

function CreateNewListingButton() {
	const country = useStore(countryStore);
	const { t } = useTranslation();
	return (
		<div className="mb-2 flex flex-col">
			<Button
				as="a"
				kind="primary"
				label={t('menu.createNewListing', 'Create new listing')}
				href={newListingPageLinks[country]}
			/>
		</div>
	);
}

function UserNavigationElement({
	items,
	userRole,
}: {
	items: User['menuItems'];
	userRole?: User['role'];
}) {
	const shouldDisplayCreateListingButton =
		userRole && ['Dealer', 'Private seller'].includes(userRole);

	return (
		<>
			{shouldDisplayCreateListingButton ? (
				<CreateNewListingButton />
			) : null}
			<ul>
				{items.map((singleItem) => (
					<li
						key={singleItem.uid}
						className="flex flex-row items-center justify-between border-t border-brand-gray-dark p-2.5 transition-all duration-300 first:border-t-0 last:border-b-0 hover:bg-brand-gray-footer"
					>
						<a
							href={singleItem.url}
							className="flex flex-grow justify-between font-medium text-brand-blue-default"
						>
							{singleItem.title}{' '}
							<div className="inline-flex flex-row items-center">
								{singleItem.count !== null && (
									<span className="text-brand-orange-default">
										<span>({singleItem.count})</span>
									</span>
								)}
								{singleItem.hasNotification ? (
									<span className="ml-2 text-brand-green-default">
										⏺
									</span>
								) : null}
							</div>
						</a>
					</li>
				))}
			</ul>
		</>
	);
}
function MobileNavListItem({
	navigationElement,
}: {
	navigationElement: (typeof navigationItems)['navList'][number];
}) {
	const { tIgnoredByParse } = useTranslation();
	const country = useStore(countryStore);
	const [isShown, setIsShown] = useState(false);
	return (
		<li
			className={`relative z-0 mx-auto w-full border-t border-brand-gray-border bg-white px-4 py-6 ${
				isShown ? ' text-left' : ''
			} `}
		>
			<button
				type="button"
				className={`flex cursor-pointer items-center justify-between text-lg font-semibold ${
					isShown ? 'text-brand-blue-default' : 'text-black'
				} `}
				onClick={() => setIsShown(!isShown)}
			>
				{tIgnoredByParse(navigationElement.i18nKey)}
				<span
					className={`relative -left-4 inline-block h-fit w-fit origin-center transition-all duration-300 ${
						isShown ? 'rotate-180' : 'rotate-0'
					}`}
				>
					<DownCaret />
				</span>
			</button>
			{isShown ? (
				<div className="submenu flex flex-grow flex-col bg-white text-brand-blue-default  ">
					{navigationElement.hasSubmenu
						? navigationElement.sections.map((subNavElement) => (
								<div className="pt-4" key={subNavElement.id}>
									<span className="block font-semibold text-brand-gray-submenu_title">
										{tIgnoredByParse(subNavElement.i18nKey)}
									</span>
									{subNavElement.items.map((subLink) =>
										subLink.href[country] ? (
											<MenuItemLink
												href={subLink.href[country]}
												isMasked={
													'shouldBeMasked' in subLink
												}
												className="flex py-1 text-brand-blue-default transition-all duration-300 hover:bg-brand-gray-footer"
											>
												<div className="flex w-full flex-grow flex-row items-center justify-between px-2.5 font-light">
													{tIgnoredByParse(
														subLink.i18nKey
													)}
												</div>
											</MenuItemLink>
										) : null
									)}
								</div>
						  ))
						: null}
				</div>
			) : null}
		</li>
	);
}

function NavListItem({
	navigationElement,
}: {
	navigationElement: (typeof navigationItems)['navList'][number];
}) {
	const { t, tIgnoredByParse } = useTranslation();
	const country = useStore(countryStore);

	return (
		<li
			role="group"
			className="group relative z-0
				bg-white px-5 py-3  hover:shadow-subnavigation [&:first-of-type>div.submenu]:-left-full"
		>
			<span
				className="text-lg font-medium
					text-black group-hover:text-brand-blue-default"
			>
				{tIgnoredByParse(navigationElement.i18nKey)}
			</span>

			<div className="submenu absolute left-0 top-full z-10 hidden min-h-full w-[540px] max-w-lg flex-grow flex-col bg-white text-brand-blue-default shadow-subnavigation transition-all duration-300 group-hover:flex">
				<div
					className={`grid ${
						navigationElement.hasSubmenu &&
						navigationElement.sections.length === 1
							? `grid-cols-[1fr]`
							: `grid-cols-[1fr,1fr]`
					} items-start pt-6`}
				>
					{navigationElement.hasSubmenu
						? navigationElement?.sections?.map((subnavElement) => (
								<div
									className="px-[30px]"
									key={`${country}-${subnavElement.id}`}
								>
									<span className="mb-3 block pt-3 font-semibold text-black">
										{/* Privatverkäufer */}
										{tIgnoredByParse(subnavElement.i18nKey)}
									</span>
									{subnavElement.items
										.filter(
											(sublink) => sublink.href[country]
										)
										.map((sublink) => (
											<MenuItemLink
												key={`${country}-${sublink.href[country]}`}
												href={sublink.href[country]!}
												isMasked={
													'shouldBeMasked' in sublink
												}
												className="flex border-t border-brand-gray-border transition-all duration-300 hover:bg-brand-gray-footer"
											>
												<div className="flex w-full flex-grow flex-row items-center justify-between gap-x-2 px-2.5 py-6">
													{tIgnoredByParse(
														sublink.i18nKey
													)}
													<span className="min-w-fit">
														<RightCaret />
													</span>
												</div>
											</MenuItemLink>
										))}
								</div>
						  ))
						: null}

					{isCtInspectionsPromotedInCountry(country) ? (
						<MaskedLink
							href="https://www.ct-inspections.com"
							target="_blank"
							rel="noopener"
							className="col-span-2 flex items-center justify-between gap-x-4 bg-brand-blue-inspections px-6 py-4 text-left text-white transition-all duration-300"
						>
							<MenuPromoBannerCtInspections
								hasCaret
								topLabel={t(
									'menu.ctInspectionsPromotion.title'
								)}
								bottomLabel={t(
									'menu.ctInspectionsPromotion.text'
								)}
							/>
						</MaskedLink>
					) : null}
				</div>
			</div>
		</li>
	);
}

type MenuItemLinkProps = RenderableProps<
	Omit<ComponentProps<'a'>, 'href' | 'rel'> & {
		isMasked?: boolean;
		href: string;
		rel?: string;
	} & ComponentProps<'button'>
>;

function MenuItemLink({ children, isMasked, ...props }: MenuItemLinkProps) {
	if (!isMasked) {
		return <a {...props}>{children}</a>;
	}
	return <MaskedLink {...props}>{children}</MaskedLink>;
}
